import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How To Integrate With Google Custome Audience",
  "author": "tzookb",
  "type": "post",
  "date": "2020-10-22T13:50:08.000Z",
  "url": "/how-to-integrate-with-google-custome-audience",
  "featuredImage": "./thumb.jpg",
  "categories": ["google", "integration"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`What is Google Custom Audience`}</li>
      <li parentName="ul">{`Flow`}</li>
      <li parentName="ul">{`Pseudo Code`}</li>
    </ul>
    <h2>{`What is Google Custom Audience`}</h2>
    <p>{`Google custom audience is a great tool for advertisers to pin point ads to existence customers they have.
You can easily filter the audiece on your end and then create the audience on Google Ads.`}</p>
    <p>{`With the above, Google provide an option to extend your reach to "similar audiences".
Google's algorithm will find audience who are similar to the audience you provided.`}</p>
    <p>{`So if you have an audience of users who are Apple fans and purchase online a lot.
Google will probably be able to find more users like that.`}</p>
    <h2>{`Flow`}</h2>
    <p>{`To be able to create a custom audience through api calls there are several steps that needs to be done.`}</p>
    <p>{`You will need to create a "project" on `}<inlineCode parentName="p">{`console.developers.google.com`}</inlineCode></p>
    <p>{`You will need to create a "credentials" for your project on `}<inlineCode parentName="p">{`console.developers.google.com/apis/credentials?project=PROJECT_NAME_HERE`}</inlineCode>{`
There are three types of "credentials": API key, Oauth client ID, Service account. I won't go over all of them as I have use them, as I use only
the "Oauth client ID" as I needed to get access from different google accounts users.`}</p>
    <p>{`Now you will need to create the flow for "Oauth client ID".
That means we need to let users open a spefcific url with our app id and redirect uri.
After the users approve our app, they will get redirected back to our app.`}</p>
    <p>{`On the redirection back, google will provide a token that we will need to use for getting the "long term token" (refresh token).
We will store the refresh token for future use for our api calls.`}</p>
    <p>{`After we got the above token we can start doint our api calls with any user interaction.`}</p>
    <h2>{`Pseudo Code`}</h2>
    <p>{`I was initially thinking on adding code, but the code will change a lot between different languages.
So not sure how useful that will be, but I'll just post some snippets with the language I used for that proejct.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`    public function removeList($listId)
    {
        $userListService = $this->getUserListService();
        $userList = new CrmBasedUserList($listId);
        $userList->setStatus('CLOSED');
        
        $operation = new UserListOperation();
        $operation->setOperand($userList);
        $operation->setOperator(Operator::SET);

        $result = $userListService->mutate([$operation]);
        return $result;
    }

    /**
     * Undocumented function
     *
     * @param [type] $name
     * @param [type] $desc
     * @return UserListReturnValue
     */
    public function createList($name, $desc)
    {
        $userListService = $this->getUserListService();
        $userList = new CrmBasedUserList();
        $userList->setName($name.' '.date('m/d/Y h:i:s a', time()));
        $userList->setDescription($desc);

        $userList->setMembershipLifeSpan(30);
        $userList->setUploadKeyType(CustomerMatchUploadKeyType::CONTACT_INFO);

        $operation = new UserListOperation();
        $operation->setOperand($userList);
        $operation->setOperator(Operator::ADD);

        $result = $userListService->mutate([$operation]);
        return $result;
    }

    public function removeUsersFromList($listId, array $members)
    {
        $userListService = $this->getUserListService();
        $mutateMembersOperation = $this->getAlterListUsersOperation($listId, $members);
        $mutateMembersOperation->setOperator(Operator::REMOVE);
        $result = $userListService->mutateMembers([
            $mutateMembersOperation
        ]);
        return $result;
    }

    public function addUsersToList($listId, array $members)
    {
        $userListService = $this->getUserListService();
        $mutateMembersOperation = $this->getAlterListUsersOperation($listId, $members);
        $mutateMembersOperation->setOperator(Operator::ADD);
        $result = $userListService->mutateMembers([
            $mutateMembersOperation
        ]);
        return $result;
    }

    /**
     * validates if we can get authorized with google.
     * Returns true if so, or throws an exception.
     * @throws \\Exception
     * @return bool
     */
    public function validate()
    {
        $res = $this->createList('cordial verify', 'marketplace verify, this can get removed');   
        return $res;
    }


    protected function getAlterListUsersOperation($listId, array $members)
    {
        $mutateMembersOperation = new MutateMembersOperation();
        $operand = new MutateMembersOperand();
        $operand->setUserListId($listId);

        // Add members to the operand and add the operation to the list.
        $operand->setMembersList($members);
        $mutateMembersOperation->setOperand($operand);
        return $mutateMembersOperation;
    }

    protected function getUserListService()
    {
        return $this->adWordsServices->get($this->session, AdwordsUserListService::class);
    }
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      